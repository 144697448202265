<template>
  <aj-card
    v-if="categoriesToShow.length"
    shadow
    class="interlinking__item"
    :class="{ '-double': isDoubleColumn }"
  >
    <aj-text
      subtitle
      class="interlinking__itemHeadline"
    >
      {{ translate('interlinking.popular_job_categories') }}
    </aj-text>
    <ul>
      <li
        v-for="(category, index) in categoriesToShow"
        :key="index"
      >
        <router-link
          :to="categoryRoute(category)"
        >
          <aj-text tag="span">
            {{ category.category.name }}
          </aj-text>
          <aj-text
            tag="span"
            class="interlinking__jobCount"
          >
            {{ category.jobOffersCount }} {{ translate('appMenu.gigs') }}
          </aj-text>
          <aj-icon
            width="12"
            shape="arrow-right-1"
          />
        </router-link>
      </li>
    </ul>
  </aj-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AjCard from '@appjobs/ui/src/components/AjCard/AjCard';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import { isMobile } from '@/js/helpers/breakpoints';

export default {
  components: {
    AjCard,
    AjIcon,
    AjText,
  },

  methods: {
    ...mapActions('categories', ['getCityCategories']),

    categoryRoute (category) {
      return {
        name: 'city_category',
        params: {
          citySlug: category.city.slug,
          categorySlug: category.category.slug,
        },
      };
    },
  },

  computed: {
    ...mapState('categories', ['cityCategories']),
    ...mapState('city', {
      cityName: state => state.name,
      cityId: state => state.id,
      citySlug: state => state.orgSlug,
    }),

    categoriesToShow () {
      const filteredCategories = this.cityCategories
        .filter(category => category.jobOffersCount > 0);
      return filteredCategories.splice(0, this.isMobile ? 6 : 12);
    },

    isDoubleColumn () {
      return this.categoriesToShow.length > 6;
    },

    isMobile () {
      return isMobile();
    },
  },

  watch: {
    cityId: {
      handler () {
        this.getCityCategories(this.citySlug);
      },
      immediate: true,
    },
  },
};
</script>
