<template>
  <aj-card
    v-if="citiesNearby.length"
    shadow
    class="interlinking__item"
  >
    <aj-text
      subtitle
      class="interlinking__itemHeadline"
    >
      {{ translate('interlinking.locations_nearby') }}
    </aj-text>
    <ul>
      <li
        v-for="(city, index) in citiesNearby"
        :key="index"
      >
        <router-link
          class="link"
          :to="cityRoute(city)"
          :title="city.name"
        >
          <aj-text tag="span">
            {{ city.name }}
          </aj-text>
          <aj-icon
            width="12"
            shape="arrow-right-1"
          />
        </router-link>
      </li>
    </ul>
  </aj-card>
</template>

<script>
import { mapState } from 'vuex';
import AjCard from '@appjobs/ui/src/components/AjCard/AjCard';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import citiesService from '@/js/services/cities';

export default {
  components: {
    AjCard,
    AjIcon,
    AjText,
  },

  data () {
    return {
      citiesNearby: [],
    };
  },

  computed: {
    ...mapState('city', ['id', 'name', 'lat', 'lng']),
  },

  watch: {
    id () {
      this.fetchNearbyCities();
    },
  },

  created () {
    this.fetchNearbyCities();
  },

  methods: {
    async fetchNearbyCities () {
      const cities = await citiesService.getNearbyCities({
        lat: this.lat,
        lng: this.lng,
        limit: 7,
        distanceLessThan: 100,
      });

      this.citiesNearby = cities.filter(city => city.name !== this.name);
    },

    cityRoute (city) {
      return { name: 'city', params: { citySlug: city.slug } };
    },
  },
};
</script>
