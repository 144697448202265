<template>
  <footer class="ui inverted vertical footer segment">
    <container-wrapper class="fluid full">
      <breadcrumb-trail
        v-if="isMobile"
        class="footer__breadcrumbs"
        show-on-mobile
      />
      <div class="footer-menu">
        <div class="item">
          <div class="icon-wrap">
            <router-link :to="{ path: routesMixin_homePageRoutePath }">
              <aj-icon
                shape="logo-white"
                width="114"
                height="26"
              />
            </router-link>
          </div>
          <div class="footer__item">
            <router-link
              class="link"
              :to="{ name: 'more_jobs' }"
              :title="translate('footer.moreJobs')"
              @click.native="sendEvent('FullTimeJobs-menuButton')"
            >
              {{ translate('footer.moreJobs') }}
              <new-label />
            </router-link>
          </div>
          <div>
            <a
              :href="routesMixin_blogRoutePath"
              class="link"
            >
              {{ translate('footer.blog') }}
            </a>
          </div>
          <div>
            <router-link
              class="link"
              :to="{ name: 'about' }"
            >
              {{ translate('footer.about') }}
            </router-link>
          </div>
          <div>
            <router-link
              class="link"
              :to="{ name: 'contact' }"
            >
              {{ translate('footer.contact') }}
            </router-link>
          </div>
          <div>
            <router-link
              class="link"
              :to="{ name: 'terms' }"
            >
              {{ translate('footer.terms') }}
            </router-link>
          </div>
          <div>
            <router-link
              class="link"
              :to="{ name: 'policy' }"
            >
              {{ translate('footer.policy') }}
            </router-link>
          </div>
        </div>
        <div class="menu item">
          <div class="ui four column stackable grid">
            <div
              v-if="nearbyCities.length"
              class="custom column"
            >
              <p class="header">
                {{ translate('footer.nearbyCities') }}
              </p>
              <div
                v-for="nearbyCity in nearbyCities"
                :key="nearbyCity.id"
              >
                <router-link
                  class="link"
                  :to="{ name: 'city', params: { citySlug: nearbyCity.slug } }"
                  :title="nearbyCity.header || translate('seo.title.city', {
                    cityName: nearbyCity.name,
                  })"
                >
                  {{ nearbyCity.name }}
                </router-link>
              </div>
            </div>
            <div
              v-if="newestCities"
              class="custom column"
            >
              <p class="header">
                {{ translate('footer.newestCities') }}
              </p>
              <div
                v-for="newestCity in newestCities"
                :key="newestCity.id"
              >
                <router-link
                  class="link"
                  :to="{ name: 'city', params: { citySlug: newestCity.slug } }"
                  :title="newestCity.header || translate('seo.title.city', {
                    cityName: newestCity.name,
                  })"
                >
                  {{ newestCity.name }}
                </router-link>
              </div>
            </div>
            <div
              v-if="topCities"
              class="custom column"
            >
              <p class="header">
                {{ translate('footer.topCities') }}
              </p>
              <div
                v-for="topCity in topCities"
                :key="topCity.id"
              >
                <router-link
                  class="link"
                  :to="{ name: 'city', params: { citySlug: topCity.slug } }"
                  :title="
                    topCity.header
                      || translate('seo.title.city', { cityName: topCity.name })
                  "
                >
                  {{ topCity.name }}
                </router-link>
              </div>
            </div>
            <div class="custom column">
              <p class="header">
                {{ translate('footer.gap.header') }}
              </p>
              <div>
                <a
                  href="https://partners.appjobs.com/"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ translate('footer.gap.pricing') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="item right">
          <div>
            <p class="header">
              {{ translate('footer.stayConnected') }}
            </p>
            <div class="social">
              <a
                href="https://www.facebook.com/AppJobsGlobal"
                target="_blank"
                class="hole column"
                rel="noopener nofollow noreferrer"
                @click="sendEvent('FooterFacebook')"
              >
                <aj-icon
                  shape="facebook-square"
                  class="circular inverted blue grey icon"
                />
              </a>
              <a
                href="https://www.youtube.com/c/AppJobsGlobal/featured"
                target="_blank"
                class="hole column"
                rel="noopener nofollow noreferrer"
                @click="sendEvent('FooterYouTube')"
              >
                <aj-icon
                  shape="youtube"
                  class="circular inverted blue grey icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/25003151/"
                target="_blank"
                class="hole column"
                rel="noopener nofollow noreferrer"
                @click="sendEvent('FooterLinkedIn')"
              >
                <aj-icon
                  shape="linkedin"
                  class="circular inverted blue grey icon"
                />
              </a>
            </div>
          </div>
          <language-selector class="inline upward" />
        </div>
      </div>
      <div class="copyright">
        {{ translate('footer.copyrights.text', {
          currentYear: new Date().getFullYear(),
        }) }}
      </div>
    </container-wrapper>
  </footer>
</template>

<style lang="scss" scoped>
  .fluid.full {
    background-color: $cl-mako;
  }

  .link {
    color: $cl-sail-darken-12;
  }
</style>

<style lang="scss">
  .footer__breadcrumbs {
    padding: 12px 0;
    border-bottom: 1px solid $cl-mako-lighten-16;

    .breadcrumbs__link {
      min-height: 30px;
      font-size: 16px;
      line-height: 30px;
      color: $cl-sail-darken-12;

      &:hover {
        color: $cl-sail;
      }

      &.-current {
        color: $cl-white;

        &:hover {
          color: $cl-white;
        }
      }
    }

    .breadcrumbs__divider {
      color: $cl-mako-lighten-16;
    }
  }
  .footer__item {
    .newLabel {
      margin-left: 5px;
      line-height: 1em;
    }
  }
</style>

<style lang="less" scoped>
  footer.ui.segment {
    padding-bottom: 0;
    padding-top: 0;
  }

  .footerLP.ten.wide.stackable {
    padding-bottom: 30px;
    padding-top: 30px;
    margin-bottom: 0;
  }

  .copyright {
    color: @grey;
    font-size: 16px;
    font-weight: 300;
    text-align: center;

    border-top: 1px solid @blueGrey;
    padding: 25px 0;
  }

  .social {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.4em;

    .column {
      &.divider {
        flex: 1 0 100%;
        @media all and (min-width: @computerBreakpoint) {
          flex: 0 1 0px;
        }
      }
      .icon {
        margin: 0 10px 10px 0;
        @media all and (min-width: @computerBreakpoint) {
          margin: 0 0 10px 10px;
        }
      }
    }
  }
</style>

<style lang="less">
  .footer-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70px;

    @media only screen and (max-width: @largestMobileScreen) {
      flex-wrap: wrap;
    }

    > .item {
      margin: 2rem 0 0;
      margin-left: 0;
      margin-right: 0;

      .icon-wrap {
        height: 50px;
      }

      @media only screen and (min-width: @tabletBreakpoint) {
        &:first-of-type {
          margin-left: 0;
          margin-right: @relativeBig;
        }

        &:last-of-type {
          margin-left: @relativeBig;
          margin-right: 0;
        }
      }

      .dropdown .text {
        font-size: 16px;
      }

      @media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
        flex: 0 1 auto;
      }
      @media only screen and (min-width: @computerBreakpoint) {
        flex: 0 1 280px;
      }

      &.menu {
        flex: 0 1 auto;
        font-size: 16px;
        @media only screen and (min-width: @computerBreakpoint) {
          flex: 1 1 auto;
        }
      }

      &.right {
        margin-bottom: 40px;
        @media only screen and (min-width: @computerBreakpoint) {
          text-align: right;
          flex: 1 1 auto;
        }
      }

      .header {
        font-size: 18px;
        height: 50px;
        margin-bottom: auto;
        font-weight: bold;
      }

      .link {
        display: inline-block;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: .8em;
      }

      .ui.stackable.grid {
        margin: auto;

        >.custom {
          padding: 0 !important;
          margin-top: 10px !important;

          @media only screen and (min-width: @tabletBreakpoint) {
            &:first-child {
              padding-right: 1rem !important;
            }

            &:last-child {
              padding-left: 1rem !important;
            }
          }
        }
      }
    }
  }
</style>

<script>
import { mapActions, mapState } from 'vuex';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import { isMobile } from '@/js/helpers/breakpoints';
import BreadcrumbTrail from '@/js/components/breadcrumb-trail';
import languageSelector from '@/js/components/selectors/language-selector';
import ContainerWrapper from '@/js/components/ui/container-wrapper';
import NewLabel from '@/js/components/ui/new-label';
import routesMixin from '@/js/mixins/routes-mixin';
import { sendGAEvent } from '@/js/helpers/analytics';

export default {
  name: 'AppFooter',

  components: {
    AjIcon,
    BreadcrumbTrail,
    languageSelector,
    ContainerWrapper,
    NewLabel,
  },

  mixins: [routesMixin],

  computed: {
    ...mapState('cities', [
      'topCities',
      'newestCities',
      'nearbyCities',
    ]),

    isMobile () {
      return isMobile();
    },
  },

  created () {
    this.getTopCities();
    this.getNewestCities();
    this.getNearbyCities(4);
  },

  methods: {
    ...mapActions('cities', [
      'getTopCities',
      'getNewestCities',
      'getNearbyCities',
    ]),

    sendEvent (eventName) {
      if (eventName === 'FullTimeJobs-menuButton') {
        sendGAEvent({
          category: 'FullTimeJobs-menuButton',
          action: 'click',
          label: 'footer-link',
        });

        return;
      }

      sendGAEvent({
        category: eventName,
        action: 'click',
        label: this.$route.name,
      });
    },
  },
};
</script>
