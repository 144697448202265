import { render, staticRenderFns } from "./app-footer.vue?vue&type=template&id=52e975fa&scoped=true&"
import script from "./app-footer.vue?vue&type=script&lang=js&"
export * from "./app-footer.vue?vue&type=script&lang=js&"
import style0 from "./app-footer.vue?vue&type=style&index=0&id=52e975fa&lang=scss&scoped=true&"
import style1 from "./app-footer.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./app-footer.vue?vue&type=style&index=2&id=52e975fa&lang=less&scoped=true&"
import style3 from "./app-footer.vue?vue&type=style&index=3&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e975fa",
  null
  
)

export default component.exports