<template>
  <div class="interlinking">
    <aj-text
      headline-small
      center
      class="interlinking__headline"
    >
      {{ translate('interlinking.find_your_next', { cityName }) }}
    </aj-text>
    <div class="interlinking__items">
      <interlinking-city-categories />
      <interlinking-category-jobs />
      <interlinking-cities-nearby />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import InterlinkingCityCategories from '@/js/components/interlinking/CityCategories';
import InterlinkingCategoryJobs from '@/js/components/interlinking/CategoryJobs';
import InterlinkingCitiesNearby from '@/js/components/interlinking/CitiesNearby';

export default {
  name: 'CityCategoryInterlinking',

  components: {
    AjText,
    InterlinkingCityCategories,
    InterlinkingCategoryJobs,
    InterlinkingCitiesNearby,
  },

  computed: {
    ...mapState('city', {
      cityName: state => state.name,
    }),
  },
};
</script>
