<template>
  <div
    id="cookieNotice"
    class="cookieNotice"
    :class="{ '-visible': !isCookieNoticeHidden }"
  >
    <aj-text
      class="cookieNotice__text"
      :html="cookieNoticeMessage"
    />
    <div class="cookieNotice__actions">
      <aj-button
        class="cookieNotice__button"
        @click.prevent="allowAll"
      >
        {{ translate('cookieNotice.button.allowAll') }}
      </aj-button>
      <aj-button
        class="cookieNotice__button"
        @click.prevent="reject"
      >
        {{ translate('cookieNotice.button.reject') }}
      </aj-button>
      <aj-button
        class="cookieNotice__button -preferences"
        subtle-light
        @click="showCookiePreferencesModal"
      >
        {{ translate('cookieNotice.button.preferences') }}
      </aj-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .cookieNotice {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 25px 16px;
    background-color: $cl-white;
    box-shadow: 0 -4px 8px $cl-black-alpha-10;
    @media (min-width: $mobile-big) {
      justify-content: center;
    }
    @media (min-width: $desktop) {
      flex-direction: row;
    }

    &.-visible {
      display: flex;
    }

    &__text {
      margin-bottom: 20px;
      color: $cl-mako;

      @media (min-width: $desktop) {
        flex-basis: 60%;
        margin: 0 20px 0 0;
        padding-left: 50px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @media (min-width: $desktop) {
        flex-direction: row-reverse;
        flex-basis: 40%;
        justify-content: center;
        width: initial;
      }
    }

    &__button {
      width: 100%;
      margin-bottom: 10px;
      @media (min-width: $desktop) {
        width: initial;
        margin: 0 10px 0 0;
      }
    }
  }
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import { COOKIE_PREFERENCES } from '@/js/enums/modal';
import { sendGAEvent } from '@/js/helpers/analytics';
import { consentModeUpdate } from '@/js/helpers/cookies';
import { redirectRoutes } from '@/js/helpers/routes';

export default {
  name: 'CookieNotice',

  components: {
    AjButton,
    AjText,
  },

  computed: {
    ...mapState('cookieNotice', ['hidden']),
    ...mapState('i18n', ['language']),

    cookieNoticeMessage () {
      return this.translate('info.cookieNotice', {
        termsLink: this.$router.resolve({ name: 'policy' }).href,
      });
    },

    isCookieNoticeHidden () {
      return this.hidden || this.isRedirectPage;
    },

    isRedirectPage () {
      return redirectRoutes.includes(this.$route.name);
    },
  },

  mounted () {
    const cookieNoticeHeight = document.getElementById('cookieNotice').clientHeight;
    this.setHeight(cookieNoticeHeight);
  },

  methods: {
    ...mapMutations('cookieNotice', ['hideCookieNotice', 'setHeight', 'allowAllCookies']),
    ...mapActions('modal', ['showModal']),

    showCookiePreferencesModal () {
      this.showModal({
        name: COOKIE_PREFERENCES,
      });

      sendGAEvent({
        category: 'CookieChoice',
        action: 'click',
        label: 'open preferences modal',
      });
    },

    reject () {
      this.hideCookieNotice();

      sendGAEvent({
        category: 'CookieChoice',
        action: 'click',
        label: 'reject cookies',
      });
    },

    allowAll () {
      this.allowAllCookies();
      consentModeUpdate();
      this.hideCookieNotice();

      sendGAEvent({
        category: 'CookieChoice',
        action: 'click',
        label: 'allow all cookies',
      });
    },
  },
};
</script>
